@media (max-width: 500px) {
  .images {
    width: 310px !important;
    height: 180px !important;
    overflow-x: hidden !important;
  }
}

@media ((min-width: 500px) and (max-width: 800px)) {
  .images {
    width: 450px !important;
    height: 250px !important;
    overflow-x: hidden !important;
  }
}

@media ((min-width: 800px) and (max-width: 1100px)) {
  .images {
    width: 750px !important;
    height: 400px !important;
    overflow-x: hidden !important;
  }
}
