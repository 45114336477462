.main__heading {
  margin: 12rem auto 1rem auto;
}

.mini__title {
  color: white;
  font-weight: 300;
  font-size: 1.3rem;
  text-align: center;
}

.form {
  width: 100%;
  justify-content: center;
}

.input.invalid {
  border-color: red;
}

.parag {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.706);
  text-align: center;
  margin-top: 6rem;
  background-color: #000;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  border-top: 1.5px solid rgba(255, 255, 255, 0.1);
}

/* .links__holder{
  display: flex;
  gap:1rem
}

.links__holder a {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  text-decoration: none;
} */

.message {
  padding-bottom: 4rem;
}

@media (max-width: 520px) {
  .icons img {
    width: 60px;
    height: 60px;
  }
}

.numberContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  gap: 1rem;
}

.numberContainer select {
  padding: 0.4rem 0;
  width: 15% !important;
}

.numberContainer input:last-child {
  width: 100% !important;
}
