.bg_design {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2),
        rgba(25, 25, 25)
      ),
      url("../../assets/Image11.jpg");
    height: 100vh;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    /* margin-top: 85px; */
  }
  
  