.timeline {
  margin-bottom: 100px;
  height: 100%;
  position: relative;
}

.col-card-left {
  background-color: rgb(25 25 25);
  border-radius: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 10px;
}

.col-card-right {
  background-color: rgb(25 25 25);
  border-radius: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 10px;
}
.container-date {
  display: none;
}
.col-note {
  color: rgb(0 198 94);
}

.col-card-title {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(0 198 94);
  background-color: rgb(25 25 25);
  padding: 1.3rem;
}

.col-card-title-left {
  text-align: left;
}

.col-card-title-right {
  text-align: left;
}

.col-card-para {
  font-size: 0.95rem;
  padding: 1.3rem;
  color: white;
  background-color: rgb(25 25 25);
  line-height: 1.4rem;
}

.triangle-left {
  width: 0;
  height: 0;
  border-left: 15px solid #fff;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  margin-top: 10px;
  margin-left: -8px;
  z-index: 1000;
  position: absolute;
}

.triangle-right {
  width: 0;
  height: 0;
  border-right: 15px solid #fff;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  margin-top: 10px;
  margin-left: 9.5%;
  z-index: 3000;
  position: absolute;
}

.col-note-left {
  text-align: right;
  margin: 1rem;
  margin-top: 1.2rem;
}

.col-note-right {
  text-align: left;
  margin: 1rem;
  margin-top: 1rem;
}

.circle {
  width: 50px;
  height: 50px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: rgb(0 198 94);
  border: 3px solid rgb(0 198 94);
  margin: 0 auto;
  z-index: 1000;
  position: relative;
}

.icon {
  z-index: 5000;
  color: #fff;
  font-size: 1.7rem;
  margin: 0 0.8rem;
  margin-top: 0.2rem;
  padding-top: 0.5rem;
}

.line {
  border-left: 3px solid rgb(0 198 94);
  height: 100%;
  position: absolute;
  left: 50%;
  z-index: 1;
  top: 0;
  bottom: 0;
}

.clearfix {
  clear: both;
}

.column-left {
  float: left;
  width: 30%;
  padding-left: 15%;
}

.column-right {
  float: right;
  width: 30%;
  padding-right: 15%;
}

.column-center {
  display: inline-block;
  width: 10%;
}
.read-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
}
.numberCircle {
  color: #fff;
  padding: initial;
  padding: 6px 10px;
  font-size: 1rem;
  font-weight: bold;
  width: max-content;
  height: max-content;
  border-radius: 2px;
  background-color: rgb(0 198 94);
  border: 2px solid rgb(0 198 94);
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-left: 1rem;
  text-decoration: none;
  cursor: pointer;
}

.carousel-image {
  width: 100%;
  height: auto;
  position: relative;
}

.fade-carousel-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.fade-carousel {
  width: 100%;
}

.carousel-item {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}

/* Customize arrow positions */
.slick-prev,
.slick-next {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  z-index: 1 !important;
}

.slick-prev {
  left: 10px !important;
}

.slick-next {
  right: 10px !important;
}

.small-size {
  display: none;
  width: 8%;
  border-bottom: 2px solid rgb(0 198 94);
  margin-bottom: 6%;
}
.date-box {
  display: none;
  padding: 2% 5%;
  background-color: rgb(0 198 94);
  color: #fff;
  border-radius: 5px;
}
@media (max-width: 860px) {
  .icon {
    z-index: 5000;
    color:#fff;
    font-size: 1.3rem;
    margin-left: 0.15rem;
    margin-top: -0.3rem;
  }
  .column-left {
    float: left;
    width: 40%;
    padding-left: 5%;
  }

  .column-right {
    float: right;
    width: 40%;
    padding-right: 5%;
  }
  .col-card-title {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(0 198 94);
    background-color: rgb(25 25 25);
    padding: 1.3rem;
  }
  .col-card-para {
    font-size: 0.85rem;
    padding: 1.3rem;
    color: white;
    background-color: rgb(25 25 25);
    line-height: 1.4rem;
  }
  .circle {
    width: 30px;
    height: 30px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: rgb(0 198 94);
    border: 3px solid rgb(0 198 94);
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }
  .line {
    border-left: 2px solid rgb(0 198 94);
    height: 100%;
    position: absolute;
    left: 50%;
    z-index: 1;
    top: 0;
    bottom: 0;
  }

  .small-size {
    display: none;
    width: 8%;
    border-bottom: 2px solid rgb(0 198 94);
    margin-bottom: 6%;
  }
  .date-box {
    display: none;
    padding: 2% 5%;
    background-color: rgb(0 198 94);
    color: rgb(7 29 73);
    border-radius: 5px;
  }
}

@media (max-width: 500px) {
  .container-date {
    margin-bottom: 10%;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }
  .small-size {
    display: block;
    width: 8%;
    border-bottom: 2px solid rgb(0 198 94);
    margin-bottom: 5%;
  }
  .date-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1% 3%;
    background-color: rgb(0 198 94);
    color: rgb(7 29 73);
    border-radius: 5px;
    font-weight: 500;
    font-size: 0.9rem;
  }
  .icon {
    z-index: 5000;
    color: #fff;
    font-size: 1.4rem !important;
  }
  .numberCircle {
    color: #fff;
    padding: initial;
    padding: 6px 10px;
    font-size: 0.9rem;
    font-weight: bold;
    width: max-content;
    height: max-content;
    border-radius: 2px;
    background-color: rgb(0 198 94);
    border: 2px solid rgb(0 198 94);
    border-top-left-radius: 7px;
    border-bottom-right-radius: 7px;
    margin-left: 1rem;
    text-decoration: none;
    cursor: pointer;
  }
  .col-card-title {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(0 198 94);
    background-color: rgb(25 25 25);
    padding: 1.3rem;
  }
  .col-card-para {
    font-size: 0.85rem;
    padding: 1.3rem;
    color: white;
    background-color: rgb(25 25 25);
    line-height: 1.4rem;
  }
  .col-note-left {
    display: none;
  }

  .col-note-right {
    display: none;
  }
  .column-left {
    float: left;
    width: 80%;
  }

  .column-right {
    float: left;
    width: 85%;
    margin-left: 5%;
  }
  .col-card-right {
    background-color: rgb(25 25 25);
    border-radius: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 10px;
  }

  .triangle-right {
    display: none;
  }
  .triangle-left {
    display: none;
  }

  .circle {
    display: none;
    width: 20px;
    height: 20px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: rgb(0 198 94);
    border: 3px solid rgb(0 198 94);
    margin: 0 25%;
    z-index: 2;
    position: relative;
    float: left;
  }
  .column-center {
    float: left;
  }
  .line {
    border-left: 2px solid rgb(0 198 94);
    height: 100%;
    position: absolute;
    left: 5%;
    z-index: 1;
    top: 0;
    bottom: 0;
  }
}
