::-webkit-scrollbar {
    width: 10px;
    height: 17px;
  }
  
  /* Background color of the scrollbar track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 7px rgba(255,255,255,0.2);
    border-radius: 15px;
    background-color:#fff;
  }
  
  /* Color of the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    border-radius: 15px;

    -webkit-box-shadow: inset 0 0 10px rgba(255,255,255,0.2);
    background-color: #fff;
  
  }
  
  /* On hover, color of the scrollbar thumb */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255,255,255,0.2);
  }