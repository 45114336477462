.heading {
  text-align: center;
  color: white;
  font-size: 4.5rem;
  font-weight: 700;

  margin-bottom: 5.5rem;
}
@media (max-width: 992px) {
  .heading {
    font-size: 3rem;
    padding: 0 .5rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.6rem;
    margin-bottom: 2.5rem;
  }
}
