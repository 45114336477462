/* .img{
  width: 90px;
  height: 90px;
} */
@media ((min-width:900px) and (max-width: 1200px)) {
    .grid_cont {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }

    .images{
      width:60px !important;
      height:60px !important;
    }

    .fonts{
      font-size: 0.65rem;
    }
  }