.button {
  background-color: rgb(0 198 94);
  padding: 0.9rem 1.7rem;
  margin: 1rem auto;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: -4.7px 11.75px 47px rgba(227, 99, 101, 0.12),
    inset 9.4px 9.4px 11.75px rgba(245, 133, 135, 0.2);
  border-radius: 17.625px 3.525px;
  cursor: pointer;
  border: none;
}
