.head{
    font-size: 1.7rem !important;
    padding-bottom: 2.5rem;
    font-weight: normal;
}

@media ((min-width:900px) and (max-width: 1200px)) {
    .grid_cont {
      padding-left: 8rem !important;
      padding-right: 8rem !important;
    }
  }