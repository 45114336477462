.location__description {
  text-align: center;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 2.2rem;
  color: #9e9c9c;
  width: 55%;
  margin: -2rem auto 3rem auto;
  position: relative;
}
.map__background {
  position: relative;
  margin-top: 85px;
}
.map__background span {
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  background-color: #9e9c9c;
  border-radius: 50%;
  box-shadow: 0 0 30px 3px #9e9c9c;
  top: 29%;
  left: 51.8%;
  transform: translate(-50%, -50%);
}

.map__background span::before {
  content: "";
  position: absolute;
  inset: 0;
  border: 1px solid #9e9c9c;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  box-shadow: 0 0 10px 2px #9e9c9c;
}
.map__background span::after {
  content: "";
  position: absolute;
  inset: 0;
  border: 1px solid #9e9c9c;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  box-shadow: 0 0 10px 2px #9e9c9c;
}
@media (min-width: 992px) {
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: scale(8);
      opacity: 0;
    }
  }
}

@media (max-width: 992px) {
  .location__description {
    width: 70%;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: scale(5);
      opacity: 0;
    }
  }
}

@media (max-width: 768px) {
  .location__description {
    width: 85%;
  }
  .map__background span {
    width: 1rem;
    height: 1rem;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: scale(6);
      opacity: 0;
    }
  }
}

@media (max-width: 576px) {
  .location__description {
    width: 90%;
    font-size: 1rem;
  }
  .map__background span {
    width: 0.5rem;
    height: 0.5rem;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: scale(5);
      opacity: 0;
    }
  }
}
