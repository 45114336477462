body {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #191919;
}

.container {
  width: 100%;
  height: 100vh;
}
.dropdown {
  width: 400px;
  user-select: none;
  margin: 100px auto;
  position: relative;
}
.dropdown .dropdown-btn {
  padding: 15px 20px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: #333;
  width: 95%;
}
.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item:hover {
  background: #f4f4f4;
}
