.bg_wlc {
  background-image: linear-gradient(
      rgba(19, 19, 19, 0.6),
      rgba(19, 19, 19, 0.7),
      rgba(19, 19, 19, 0.9),
      rgba(25, 25, 25)
    ),
    url("../../assets/locationReplaced.jpg");
  height: 86vh;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 7% 2% 0% 2%;
  margin-top: 85px;
}

.wlc_txt {
  font-size: 3.2rem;
  color: white;
  text-align: center;
  line-height: 42px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.txt {
  font-size: 21px;
  color: white;
  text-align: center;
  line-height: 37px;
  font-weight: lighter;
  width: 70%;
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 3% 15%;
}

/* Scroll down arrow */

.arrow_container {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0px auto;
}

.chevron {
  position: absolute;
  width: 35px;
  height: 7px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: rgb(0 198 94);
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}
.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}
@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}
html {
  scroll-behavior: smooth;
}
@media screen and (max-width: 982px) {
  .bg_wlc {
    height: 85vh;
    padding: 0% 5%;
    padding-top: 3%;
    background-image: linear-gradient(
        rgba(19, 19, 19, 0.6),
        rgba(19, 19, 19, 0.7),
        rgba(25, 25, 25)
      ),
      url("../../assets/locationReplaced.jpg");
    padding: 7% 2% 0% 2%;
  }
  .wlc_txt {
    font-size: 2.9rem;
    line-height: 35px;
  }
  .txt {
    font-size: 18px;
  }
}
@media screen and (max-width: 700px) {
  .bg_wlc {
    padding: 0% 5%;

    background-image: linear-gradient(
        rgba(19, 19, 19, 0.6),
        rgba(19, 19, 19, 0.7),
        rgba(25, 25, 25)
      ),
      url("../../assets/locationReplaced.jpg");
    padding: 7% 7% 0% 7%;
  }
  .wlc_txt {
    font-size: 2.4rem;
    line-height: 30px;
  }

  .txt {
    font-size: 16px;
    line-height: 30px;
    width: 80%;
    /* padding-bottom: 2%; */
  }
  .btns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 7% 2%;
  }
}

@media screen and (max-width: 490px) {
  .wlc_txt {
    font-size: 1.6rem;
    line-height: 28px;
  }
  .txt {
    font-size: 15px;
    width: 95%;
  }
  .btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
