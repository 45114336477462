.input {
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1.5px solid white;
  padding-bottom: 0.6rem;
  font-weight: 400;
  font-size: 0.9rem;
  width: 98%;
  color: white;
  padding-left: 0.5rem;

  margin: 1rem 0;
}

.input::placeholder {
  color: rgba(255,255,255,0.5);
  font-size: 0.9rem;
}

@media (max-width: 900px) {
  .input {
    width: 98%;
  }
}


/* Hide arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

/* Hide arrows in Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.custom-select {
  background-color: black !important;
  color: white; /* Optional: to make the text readable */
}