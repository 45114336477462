.bg-wlc {
  background-image: linear-gradient(
      rgba(19, 19, 19, 0.6),
      rgba(19, 19, 19, 0.7),
      rgba(25, 25, 25)
    ),
    url("../../../assets/header-replaced.jpg");
  height: 86vh;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 7% 2% 0% 2%;
  margin-top: 85px;
}

.wlc-txt {
  font-size: 62px;
  color: white;
  text-align: center;
  line-height: 42px;
}

.txt {
  font-size: 21px;
  color: white;
  text-align: center;
  line-height: 37px;
  font-weight: lighter;
  padding-top: 1%;
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 3% 15%;
}

.wlc-btn {
  color: rgb(7 29 73);
  background-color: rgb(0 198 94);
  font-weight: bolder;
  border-bottom-right-radius: 15px;
  border-color: rgb(0 198 94);
  border-top-left-radius: 15px;
  padding: 2% 5.5%;
  cursor: pointer;
  font-size: 21px;
}

.btn-hover:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgb(0 198 94); 
  border: 3px solid rgb(0 198 94);
}

@media screen and (max-width: 982px) {
  .bg-wlc {
    height: 85vh;
    padding: 0% 5%;
    padding-top: 3%;
    background-image: linear-gradient(
      rgba(19, 19, 19, 0.6),
      rgba(19, 19, 19, 0.7),
      rgba(25, 25, 25)
    ),
    url("../../../assets/header-replaced.jpg");
    padding: 7% 2% 0% 2%;
  }
  .wlc-txt {
    font-size: 50px;
  }
  .txt {
    font-size: 18px;
  }
  .wlc-btn {
    padding: 2% 8%;
    font-size: 14px;
    font-weight: bolder;
  }
}
@media screen and (max-width: 932px) {
  .txt br {
    display: none;
  }

  .txt {
    width: 80%;
    margin: 0% auto;
  }
}
@media screen and (max-width: 700px) {
  .bg-wlc {
    padding: 0% 5%;

    background-image: linear-gradient(
        rgba(19, 19, 19, 0.6),
        rgba(19, 19, 19, 0.7),
        rgba(25, 25, 25)
      ),
      url("../../../assets/header-replaced.jpg");
    padding: 7% 7% 0% 7%;
  }
  .wlc-txt {
    font-size: 42px;
  }

  .txt {
    font-size: 16px;
    line-height: 33px;
    padding-bottom: 2%;
  }
  .wlc-btn {
    padding: 2% 8%;
    font-size: 13px;
    font-weight: bolder;
  }
  .btns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 7% 2%;
  }
}

@media screen and (max-width: 400px) {
  .wlc-txt {
    font-size: 28px;
    line-height: 28px;
  }
  .txt {
    font-size: 15px;
  }
  .btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .wlc-btn {
    padding: 5% 8%;
    font-size: 13px;
    font-weight: bolder;
    margin-bottom: 5%;
  }
}
